body {
  padding: 0;
  margin: 0;
  width: 100%;
}

a,
a:hover {
  text-decoration: none;
}

.main-wrapper {
  display: flex;
  width: 100vw;
}

.main-content-wrapper {
  margin-left: 250px;
  margin-top: 130px;
}

.remove-padding {
  margin-left: -8;
  margin-right: -8;
  margin-top: -8;
  margin-bottom: -8;
}

.top-bar {
  position: fixed;
  width: 100%;
  z-index: 500;
  top: 0;
}
